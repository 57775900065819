/*
 * PrivacyManager's popin Management
 */
import css from '!!css-loader!./Popin/popin.css';
import { setAdsOptOut, setStatsAndAnonymousOptOut } from '../Actions/PrivacyActions';
import { initAfterUserConsent } from '../Actions/TrackingActions';
import { DATA_DOMAIN, VENDORLIST_PATH_LOCAL, VENDORLIST_PATH_PROD } from '../PrivacyManager/Constants';
import { addClass, removeClass } from '../Utils';
import { renderPopin } from './Popin/popinRenderer';

const vendorListSrc = __LOCAL__ ? VENDORLIST_PATH_LOCAL : `https://${DATA_DOMAIN}${VENDORLIST_PATH_PROD}`;

window.getVendorList = async () => {
  const store = window.ttStore;
  const state = store.getState();

  try {
    return await fetch(vendorListSrc)
      .then((res) => res.json())
      .then((vendors) => [...state.config.lang.customVendors, ...vendors]);
  } catch (e) {
    console.error('Error during fetch,', e);
  }
};

const renderVendors = (currentLang, vendorList = []) => {
  let rows = '';
  vendorList.forEach((vendor) => {
    rows += `
        <tr>
          <td><a class="vendors" href="${vendor.policyUrl}" target="_blank" rel="noreferrer" aria-label="${vendor.name} - ${currentLang.a11y.newWindow}">${vendor.name}</a></td>
        </tr>
      `;
  });

  return `
      <table colspan="0" border="0" cellspacing="0">
        <thead>
          <th>${currentLang.privacyPopin.vendorTableTitle}</th>
        </thead>
        <tbody>${rows}</tbody>
      </table>
    `;
};

export const addPrivacyManager = () => {
  // Do not add the popin if one already exists
  if (document.getElementById('privacy-manager-popin')) return;

  const store = window.ttStore;
  const state = store.getState();
  store.dispatch({ type: 'OPEN_PRIVACY_SETTINGS' }); // Log, doesn't change the state

  // Add the style
  const style = document.createElement('style');
  style.innerHTML = `${css[0][1]}\n${css[1][1]}`;
  document.head.appendChild(style);

  // Add the popin to the document
  const pmElement = document.createElement('div');
  pmElement.id = 'privacy-manager-popin';
  pmElement.setAttribute('data-nosnippet', '');
  pmElement.innerHTML = renderPopin(state.config.lang, state.config.brand);
  document.body.insertBefore(pmElement, document.body.firstChild);

  // Here we send a tracking event at the display of the popin
  // Note that in case of first visit, the event will only be sent after user's interaction with the popin
  // This is due to the fact that we don't have user's tracking consent on their first visit
  sendPopinDisplayEvent(state.statsAllowed, state.adsAllowed);

  // Set the initial state of the checkboxes and bind the onclick on them
  const displayOptoutOptions = document.getElementById('pm-optout-display');
  const optoutBtnArrowDown = document.getElementById('optout-arrow-down');
  const optoutBtnArrowUp = document.getElementById('optout-arrow-up');
  const optoutOptionsBlock = document.getElementById('pm-optout-block');
  const cbOptoutStats = document.getElementById('cb-optout-stats');
  const cbOptoutAds = document.getElementById('cb-optout-ads');
  const displayVendorsButton = document.getElementById('pm-ads-vendors');
  const vendorList = document.getElementById('pm-vendor-list');
  const vendorListBtnArrowDown = document.getElementById('vendor-arrow-down');
  const vendorListBtnArrowUp = document.getElementById('vendor-arrow-up');
  const declineBtn = document.getElementById('decline-btn');
  const refuseBtn = document.getElementById('refuse-btn');
  const acceptBtn = document.getElementById('accept-btn');
  const exitBtn = document.getElementById('save-exit-btn');
  const exitContainer = document.getElementById('save-exit-container');

  displayOptoutOptions.setAttribute('aria-expanded', false);
  displayVendorsButton.setAttribute('aria-expanded', false);

  // Focus the popin so voiceOver cannot exit it
  document.getElementById('popin-content')?.focus();

  const trapFocus = (e) => {
    const focusableEls = document.querySelectorAll('.pm-focusable');
    const firstFocusableElement = focusableEls[0];
    const lastFocusableElement =
      document.querySelector('#refuse-btn.options-opened') ?? focusableEls[focusableEls.length - 1] ?? declineBtn;
    const isTabPressed = e.key === 'Tab';

    if (!isTabPressed) {
      return;
    }

    /* Pressing TAB */
    if (isTabPressed && document.activeElement === lastFocusableElement) {
      firstFocusableElement.focus();
      e.preventDefault();
    }

    /* Pressing SHIFT + TAB */
    if (e.shiftKey && document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus();
      e.preventDefault();
    }
  };

  // Trap the focus inside the GDPR popin
  document.addEventListener('keydown', trapFocus);

  cbOptoutStats.checked = state.statsAllowed;
  cbOptoutAds.checked = state.adsAllowed;

  // When the user presses on the "Enter" key on the "Stats" toggle
  cbOptoutStats.onkeydown = (event) => {
    if (event.key === 'Enter') {
      cbOptoutStats.checked = !cbOptoutStats.checked;
    }
  };

  // When the user presses on the "Enter" key on the "Ads" toggle
  cbOptoutAds.onkeydown = (event) => {
    if (event.key === 'Enter') {
      cbOptoutAds.checked = !cbOptoutAds.checked;
    }
  };

  const handlePopinButtonClick = (statsAllowed, adsAllowed) => {
    sendPopinInteractionEvent(statsAllowed, adsAllowed);
    setStatsAndAnonymousOptOut(store, statsAllowed);
    setAdsOptOut(store, adsAllowed);
    cbOptoutStats.checked = statsAllowed;
    cbOptoutAds.checked = adsAllowed;
    initAfterUserConsent(store);
    document.removeEventListener('keydown', trapFocus);
    closePrivacyManager();
  };

  // When the user clicks on the "Refuse all" button at the top right of the popin (with options closed)
  declineBtn.onclick = () => {
    handlePopinButtonClick(false, false);
  };

  // When the user clicks on the "Refuse all" button next to the "Accept all" button (with options opened)
  refuseBtn.onclick = () => {
    handlePopinButtonClick(false, false);
  };

  // When the user clicks on the "Accept all" button
  acceptBtn.onclick = () => {
    handlePopinButtonClick(true, true);
  };

  // When the user clicks on the "Save & exit" button
  exitBtn.onclick = () => {
    handlePopinButtonClick(cbOptoutStats.checked, cbOptoutAds.checked);
  };

  // When the user clicks on the "Learn more" button
  displayOptoutOptions.onclick = () => {
    if (optoutOptionsBlock.className.includes('options-opened')) {
      displayOptoutOptions.setAttribute('aria-expanded', false);
      removeClass(
        [optoutBtnArrowDown, optoutBtnArrowUp, optoutOptionsBlock, declineBtn, exitContainer, refuseBtn],
        'options-opened'
      );
    } else {
      displayOptoutOptions.setAttribute('aria-expanded', true);
      addClass(
        [optoutBtnArrowDown, optoutBtnArrowUp, optoutOptionsBlock, declineBtn, exitContainer, refuseBtn],
        'options-opened'
      );
    }
  };

  // When the user clicks on the "Show vendor list" button
  displayVendorsButton.onclick = () => {
    // Fetch the IAB vendor list when the user clicks on "our partners" button if it isn't already loaded
    if (!vendorList.innerHTML) {
      getVendorList().then((vendors) => {
        vendorList.innerHTML = renderVendors(state.config.lang, vendors);
      });
    }

    if (vendorList.className.includes('vendor-opened')) {
      displayVendorsButton.setAttribute('aria-expanded', false);
      removeClass([vendorListBtnArrowDown, vendorListBtnArrowUp, vendorList], 'vendor-opened');
    } else {
      displayVendorsButton.setAttribute('aria-expanded', true);
      addClass([vendorListBtnArrowDown, vendorListBtnArrowUp, vendorList], 'vendor-opened');
    }
  };
};

const sendPopinDisplayEvent = (stats = false, ads = false) => {
  // In case of window.sendEvent not being rightly initialized
  // OR when using privacyManager standalone library (provides only the popin, not the tracking part)
  if (window.sendEvent !== undefined) {
    sendEvent({
      name: 'viewed preferences popin',
      data: {
        'preferences_analytics': stats.toString(),
        'preferences_ads': ads.toString(),
      },
    });
  }
};

const sendPopinInteractionEvent = (stats = false, ads = false) => {
  // In case of window.sendEvent not being rightly initialized
  // OR when using privacyManager standalone library (provides only the popin, not the tracking part)
  if (window.sendEvent !== undefined) {
    sendEvent({
      name: 'submit preferences popin',
      data: {
        'preferences_analytics_chosen': stats.toString(),
        'preferences_ads_chosen': ads.toString(),
      },
    });
  }
};

const closePrivacyManager = () => {
  const store = window.ttStore;
  // Basically remove the HTML
  const privacyManager = document.getElementById('privacy-manager-popin');

  // dispatch Event before remove privacyManager dom element to give possibility to restore focus manuelly
  window.dispatchEvent(new Event('CLOSE_PRIVACY_SETTINGS'));

  if (privacyManager) {
    privacyManager.parentNode.removeChild(privacyManager);
  }

  store.dispatch({ type: 'CLOSE_PRIVACY_SETTINGS' }); // Log, doesn't change the state
};

window.launchPrivacyManager = () => addPrivacyManager();
window.sendPopinDisplayEvent = (stats, ads) => sendPopinDisplayEvent(stats, ads);
window.sendPopinInteractionEvent = (stats, ads) => sendPopinInteractionEvent(stats, ads);

export const removePrivacyManager = () => {
  document.getElementById('privacy-manager-popin')?.remove();
};
