import { naturalSort, replaceDiacritics } from '../Utils';

/**
 * Constructor - Initialize the dataLayer
 */
export default class TtDataLayer {
  constructor(channel) {
    // DataLayer initialisation
    this.dl = {
      channel,
    };
  }

  reset() {
    this.dl = {
      channel: this.dl.channel,
    };
  }

  printDataLayerInConsole() {
    if (typeof console.table === 'function') {
      // convert DataLayer to array for a better console.table
      const dataLayer = this.dl; // we use a ref because the this is not the same in map function
      const dataLayerInArray = Object.keys(dataLayer).map((key) => {
        // stringify events only for display and return new sub array
        return [key, key === 'events' ? dataLayer.events.join(',') : dataLayer[key]];
      });
      // sort by alphanum
      dataLayerInArray.sort(naturalSort);

      console.table(dataLayerInArray);
    } else {
      console.log(this.dl);
    }
  }

  add(property, value) {
    if (value) {
      this.dl[property] = replaceDiacritics(value); // we need to remove accents for every field
    }
  }

  addOrRemoveIfEmpty(property, value) {
    if (value) {
      this.add(property, value);
    } else {
      this.remove(property);
    }
  }

  remove(property) {
    if (this.dl[property]) {
      delete this.dl[property];
    }
  }

  mergeObjectInDataLayer(object) {
    if (object) {
      // For retro compatibility issues. Website used to pass string joined with coma.
      if (typeof object.events === 'string') {
        object.events = object.events === '' ? [] : object.events.split(',');
      }
      // Merge all object into the dataLayer
      Object.keys(object).forEach((key) => {
        this.add(key, object[key]);
      });
    }
  }

  get(property) {
    if (property && this.dl[property]) {
      return this.dl[property];
    }
    return null;
  }

  getAll() {
    return this.dl;
  }

  clone() {
    const clonedObject = {};
    const dataLayer = this.getAll();
    // Copy everyVar into the dataLayer object.
    Object.keys(dataLayer).forEach((key) => {
      clonedObject[key] = dataLayer[key];
    });

    return clonedObject;
  }

  addEvent(eventsToAdd) {
    let events = this.get('events') || [];
    let newEventsToAdd = [];

    if (!eventsToAdd || eventsToAdd.length === 0) return events;

    // For retro compatibility issues. Website used to pass string joined with coma.
    if (typeof events === 'string') {
      events = events.split(',');
    }
    if (typeof eventsToAdd === 'string') {
      newEventsToAdd = eventsToAdd.split(',');
    }

    events = events.concat(newEventsToAdd);

    // Add the event to the array
    this.add('events', events);

    return events;
  }

  // Generate a number between 0 and 100 to downsample if needed (ex: select only event from 0 to 50 for half of them)
  addSampling() {
    this.add('sampling', Math.floor(Math.random() * 100) + 1);
  }

  // Set a flag to identify if the current dataLayer is the first event after the library has loaded
  setFirstEventSinceLoad() {
    this.firstEventSinceLoad = true;
  }

  // Unset a flag to identify if the current dataLayer is the first event after the library has loaded
  unsetFirstEventSinceLoad() {
    this.firstEventSinceLoad = false;
  }

  // Get whether the current dataLayer is for the first event after the library has loaded
  isFirstEventSinceLoad() {
    return !!this.firstEventSinceLoad;
  }

  // Set a flag to identify if the current dataLayer is the first event of the current SESSION
  setFirstEventSinceSessionStart() {
    this.firstEventSinceSessionStart = true;
  }

  // Unset a flag to identify if the current dataLayer is the first event the current SESSION
  unsetFirstEventSinceSessionStart() {
    this.firstEventSinceSessionStart = false;
  }

  // Get whether the current dataLayer is for the first event of the current SESSION or not
  isFirstEventSinceSessionStart() {
    return !!this.firstEventSinceSessionStart;
  }
}
