import { getLangConfig } from '../PrivacyManager/lang';

export const initConfig = (store, includePopin, tealiumKey, zone, locale, brand, environment) => {
  let logs;
  let stagingEnv;

  // Init the staging config. Try / catch because we don't trust sessionStorage
  try {
    logs = sessionStorage.getItem('TMSdebugLogs') || false;
    stagingEnv = sessionStorage.getItem('TMSstaging') || false;
  } catch (e) {
    logs = false;
    stagingEnv = false;
  }

  const config = {
    env: stagingEnv ? 'dev' : 'prod',
    logs: logs === 'true',
    tealiumKey,
    forceHidePopin: !includePopin,
    zone,
    locale,
    lang: getLangConfig(zone, locale),
    brand,
    environment,
  };

  store.dispatch({ type: 'INIT_CONFIG', config });
};

export const toggleLogs = () => {
  if (!sessionStorage.getItem('TMSdebugLogs')) {
    sessionStorage.setItem('TMSdebugLogs', true);
    console.warn('Logs enabled');
    window.ttStore.dispatch({ type: 'TOGGLE_LOGS', value: true });
  } else {
    sessionStorage.removeItem('TMSdebugLogs');
    console.warn('Logs disabled');
    window.ttStore.dispatch({ type: 'TOGGLE_LOGS', value: false });
  }
};

export const toggleStaging = () => {
  if (!sessionStorage.getItem('TMSstaging')) {
    sessionStorage.setItem('TMSstaging', true);
    console.warn('Staging enabled for the TMS. Please, reload the page for immediate effect.');
    window.ttStore.dispatch({ type: 'TOGGLE_ENV', value: true });
  } else {
    sessionStorage.removeItem('TMSstaging');
    console.warn('Staging disabled for the TMS. Please, reload the page for immediate effect.');
    window.ttStore.dispatch({ type: 'TOGGLE_ENV', value: false });
  }
};
