import { applyMiddleware, compose, createStore } from 'redux';
import { consentChangeMiddleware, extendOrRenewSessionMiddleware } from '../Actions/Middleware';
import reducers from '../Actions/Reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name: 'TrackingTool',
    })
  : compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(consentChangeMiddleware, extendOrRenewSessionMiddleware))
);

export default store;
