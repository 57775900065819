const configs = [
  {
    'zones': ['fr'],
    'locale': 'fr',
    'privacyPopin': {
      'OLD_title': 'Paramétrage des cookies sur les Sites de GROUPE CANAL+',
      'title': 'À propos de vos données',
      'mainText':
        'Des cookies sont déposés sur les Sites de GROUPE CANAL+, qui fait partie du groupe VIVENDI, notamment pour garantir leur bon fonctionnement et personnaliser votre expérience, vos contenus ou publicités. Vous pouvez, si vous le souhaitez, désactiver les cookies par catégorie.',
      'firstParagraph':
        "Groupe CANAL+ et ses filiales et nos %partners%, notamment les sociétés du %vivendiGroup%, utilisons des traceurs pour mesurer l'utilisation et les performances de nos services et vous proposer des publicités plus pertinentes sur la base de votre navigation et de votre profil.",
      'OLD_firstParagraph':
        'Groupe CANAL+ et ses filiales (membres du %vivendiGroup%) et nos %partners% utilisons des traceurs pour gérer et améliorer nos services et vous proposer des publicités plus pertinentes.',
      'secondParagraph':
        'Vous pouvez en savoir plus sur l’utilisation de vos données et mettre à jour vos choix à tout moment via notre rubrique %cookies%.',
      'OLD_secondParagraph':
        'Vous pouvez paramétrer vos choix par finalité et les mettre à jour à tout moment via notre rubrique %confidentiality%.',
      'thirdParagraph':
        'Parce que vous êtes connecté à votre compte CANAL+, votre choix sera valable sur l’ensemble des supports numériques du Groupe CANAL+ connectés à ce compte.',
      'thirdParagraphUnlog':
        'Votre choix ne sera valable que sur le support numérique actuellement utilisé. Si vous vous connectez à votre compte CANAL+, vos choix exprimés en mode connecté prévaudront.',
      'learnMore': 'En savoir + et paramétrer mes choix',
      'OLD_firstCategoryTitle': 'Cookies nécessaires au fonctionnement',
      'firstCategoryTitle': 'Fonctionnement du service (requis)',
      'OLD_firstCategoryText':
        'Il s’agit des cookies de session d’utilisateur, d’authentification, de sécurité qui sont  strictement nécessaires au fonctionnement du Site.',
      'firstCategoryText':
        "Ces identifiants permettent d'assurer le fonctionnement du service, en permettant l'authentification des utilisateurs, la sécurité et la supervision technique (anonyme) du service. Il n'est pas possible de désactiver ce réglage.",
      'OLD_secondCategoryTitle': 'Analyse d’audience',
      'secondCategoryTitle': 'Analyse de la performance',
      'OLD_secondCategoryText':
        'Les cookies statistiques permettent de mesurer l’utilisation et les performances du Site et d’en améliorer le fonctionnement.',
      'secondCategoryText':
        "Ces identifiants permettent de mesurer l'utilisation et les performances de nos services (à l'exclusion de la mesure d'audience anonyme) pour mieux nous adapter à vos attentes.",
      'OLD_thirdCategoryTitle': 'Publicité',
      'thirdCategoryTitle': 'Publicité personnalisée',
      'OLD_thirdCategoryText':
        'Les cookies publicitaires nous permettent, avec nos partenaires publicitaires, de vous proposer des publicités adaptées à vos centres d’intérêt. La désactivation de ces cookies n’aura pas d’impact sur le volume de publicités, mais uniquement sur leur pertinence.',
      'thirdCategoryText1':
        "Ces identifiants permettent, avec nos partenaires, de vous proposer des publicités adaptées à vos centres d'intérêt. Plus précisément, nous pourrons&nbsp;:",
      'thirdCategoryItem1':
        'adapter les contenus publicitaires selon la navigation de votre terminal ou les données personnelles que vous avez fournies, notamment en utilisant des fonctionnalités de ciblage (retargeting) pour vous présenter des publicités ou des contacts email suite à votre utilisation de nos services&nbsp;;',
      'thirdCategoryItem2': 'suivre la navigation ultérieure effectuée par votre terminal&nbsp;;',
      'thirdCategoryItem3': "adapter les espaces publicitaires aux préférences d'affichage de votre terminal&nbsp;;",
      'thirdCategoryItem4': 'adapter la présentation du service auquel mène un contenu publicitaire&nbsp;;',
      'thirdCategoryItem5': 'limiter le nombre de fois où vous voyez une publicité&nbsp;;',
      'thirdCategoryItem6': 'calculer les sommes dues aux acteurs de la chaîne de diffusion publicitaire&nbsp;;',
      'thirdCategoryItem7': "mesurer l'efficacité et l'impact d'une campagne publicitaire.",
      'thirdCategoryText2':
        "En désactivant ces identifiants, vous ne serez pas exposés à moins de publicités, mais à des publicités ne correspondant pas nécessairement à vos centres d'intérêts.",
      'OLD_vendorListButton': 'Afficher la liste des partenaires publicitaires',
      'vendorListButton': 'Liste des partenaires',
      'vendorTableTitle': 'Société',
      'cookiePolicyLabel': 'Afficher notre politique cookie',
      'declineButton': 'Continuer sans accepter',
      'refuseButton': 'Tout refuser',
      'acceptButton': 'Tout accepter',
      'saveAndCloseButton': 'Enregistrer et fermer',
      'partners': 'partenaires',
      'services': 'liste des supports',
      'confidentiality': 'Confidentialité',
      'cookies': 'Cookies',
      'cookiePolicy': 'notre politique cookie',
      'vivendiGroup': 'groupe Vivendi',
    },
    'cookiePolicyLink':
      'https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.canalplus.com%2Fcookies%2F&data=05%7C01%7Caccessibility%40canal-plus.com%7C823c1ca48f0e4aa5619308dadeb8aac0%7Cbf5c5de16a544091a72f90e32801628c%7C0%7C0%7C638067181957704103%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=%2F%2BOBIfCLeee%2BxzL4ssnB0cc3iyHR0g6Fv%2BPCECmayZM%3D&reserved=0',
    'partnersLink': 'https://static.canalplus.com/legal/liste-partenaires.html',
    'vivendiGroupLink':
      'https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.vivendi.com%2Fwp-content%2Fuploads%2F2022%2F11%2FVivendi-Organigramme-simplifie-au-30-septembre-2022-version-accessible.pdf&data=05%7C01%7Caccessibility%40canal-plus.com%7C823c1ca48f0e4aa5619308dadeb8aac0%7Cbf5c5de16a544091a72f90e32801628c%7C0%7C0%7C638067181957704103%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=h18CFEGAfLnvVqBZ6jHQ0%2BArXmAtDUznsj152kEoXHY%3D&reserved=0',
    'a11y': {
      'popinTitle': 'Groupe Canal Plus, le respect de votre vie privée est une priorité',
      'learnMore': 'En savoir plus et paramétrer vos consentements',
      'acceptButton': 'Accepter notre traitement des données',
      'declineButton': 'Continuer sans accepter notre traitement des données',
      'saveAndCloseButton': 'Enregistrer vos paramètres et continuer',
      'refuseButton': 'Refuser notre traitement des données',
      'vivendiGroupLink': 'Groupe Vivendi - nouvelle fenêtre',
      'partnersLink': 'Nos partenaires - nouvelle fenêtre',
      'newWindow': 'nouvelle fenêtre',
      'cookiesLink': 'Cookies - nouvelle fenêtre',
    },
    'customVendors': [
      { 'name': 'Vivendi', 'policyUrl': 'https://www.vivendi.com/footer/cookies-policy/' },
      { 'name': 'Dailymotion', 'policyUrl': 'https://www.dailymotion.com/legal/privacy' },
      { 'name': 'Gameloft', 'policyUrl': 'https://www.gameloft.com/fr/legal/showcase-cookie-policy' },
      { 'name': 'Editis', 'policyUrl': 'https://www.editis.com/gestion-des-cookies/' },
      { 'name': 'Vivendi Village', 'policyUrl': 'https://www.vivendi.com/footer/cookies-policy/' },
      {
        'name': 'Mediarithmics',
        'policyUrl': 'https://www.mediarithmics.com/fr-fr/content/charte-de-protection-des-donnees-personnelles',
      },
      { 'name': 'Betasérie', 'policyUrl': 'https://www.betaseries.com/conditions' },
      { 'name': 'Facebook', 'policyUrl': 'https://www.facebook.com/policies/cookies/' },
      { 'name': 'Mozoo', 'policyUrl': 'http://www.mozoo.fr/' },
      { 'name': 'Powerspace', 'policyUrl': 'https://powerspace.com/fr/privacy/' },
      { 'name': 'Snapchat', 'policyUrl': 'https://snap.com/fr-FR/cookie-policy' },
      { 'name': 'Adot', 'policyUrl': 'https://we-are-adot.com/privacy-policy/' },
      { 'name': 'CCM Benchmark', 'policyUrl': 'http://www.ccmbenchmark.com/donnees-personnelles?origin=s_institut' },
      { 'name': 'Emoteev', 'policyUrl': 'https://www.emoteev.io/fr/page-daccueil/' },
      { 'name': 'Horyzon', 'policyUrl': 'https://www.horyzon.com/' },
      { 'name': 'Pinterest', 'policyUrl': 'https://policy.pinterest.com/fr/privacy-policy' },
      {
        'name': 'Reworld',
        'policyUrl':
          'https://www.reworldmedia.com/wp-content/uploads/2020/09/Politique-donn%C3%A9es-personnelles-et-cookies.pdf',
      },
      { 'name': 'Tiktok', 'policyUrl': 'https://www.tiktok.com/legal/tiktok-website-cookies-policy?lang=fr' },
      { 'name': 'Tradedoubler', 'policyUrl': 'https://www.tradedoubler.com/fr/privacy-policy/' },
      { 'name': 'Twitter', 'policyUrl': 'https://help.twitter.com/fr/rules-and-policies/twitter-cookies' },
      { 'name': 'Freewheel', 'policyUrl': 'https://www.freewheel.com/privacy-policy-fr' },
      { 'name': 'Acast', 'policyUrl': 'https://www.acast.com/en/privacy' },
    ],
  },
  {
    'zones': [
      'bf',
      'bi',
      'bj',
      'bl',
      'cd',
      'cf',
      'cg',
      'ch',
      'ci',
      'cm',
      'cv',
      'dj',
      'ga',
      'gf',
      'gh',
      'gm',
      'gn',
      'gp',
      'gq',
      'gw',
      'ht',
      'km',
      'kn',
      'mc',
      'mf',
      'mg',
      'ml',
      'mq',
      'mr',
      'mu',
      'nc',
      'ne',
      'ng',
      're',
      'rw',
      'sl',
      'sn',
      'td',
      'tg',
      'wf',
      'yt',
      'pf',
    ],
    'locale': 'fr',
    'privacyPopin': {
      'OLD_title': 'Paramétrage des cookies sur les Sites de GROUPE CANAL+',
      'title': 'À propos de vos données',
      'mainText':
        'Des cookies sont déposés sur les Sites de GROUPE CANAL+, qui fait partie du groupe VIVENDI, notamment pour garantir leur bon fonctionnement et personnaliser votre expérience, vos contenus ou publicités. Vous pouvez, si vous le souhaitez, désactiver les cookies par catégorie.',
      'firstParagraph':
        "Groupe CANAL+ et ses filiales et nos %partners%, notamment les sociétés du %vivendiGroup%, utilisons des traceurs pour mesurer l'utilisation et les performances de nos services et vous proposer des publicités plus pertinentes sur la base de votre navigation et de votre profil.",
      'OLD_firstParagraph':
        'Groupe CANAL+ et ses filiales (membres du %vivendiGroup%) et nos %partners% utilisons des traceurs pour gérer et améliorer nos services et vous proposer des publicités plus pertinentes.',
      'secondParagraph':
        'Vous pouvez en savoir plus sur l’utilisation de vos données et mettre à jour vos choix à tout moment via notre rubrique %cookies%.',
      'OLD_secondParagraph':
        'Vous pouvez paramétrer vos choix par finalité et les mettre à jour à tout moment via notre rubrique %confidentiality%.',
      'thirdParagraph':
        'Parce que vous êtes connecté à votre compte CANAL+, votre choix sera valable sur l’ensemble des supports numériques du Groupe CANAL+ connectés à ce compte.',
      'thirdParagraphUnlog':
        'Votre choix ne sera valable que sur le support numérique actuellement utilisé. Si vous vous connectez à votre compte CANAL+, vos choix exprimés en mode connecté prévaudront.',
      'learnMore': 'En savoir + et paramétrer mes choix',
      'OLD_firstCategoryTitle': 'Cookies nécessaires au fonctionnement',
      'firstCategoryTitle': 'Fonctionnement du service (requis)',
      'OLD_firstCategoryText':
        'Il s’agit des cookies de session d’utilisateur, d’authentification, de sécurité qui sont  strictement nécessaires au fonctionnement du Site.',
      'firstCategoryText':
        "Ces identifiants permettent d'assurer le fonctionnement du service, en permettant l'authentification des utilisateurs, la sécurité et la supervision technique (anonyme) du service. Il n'est pas possible de désactiver ce réglage.",
      'OLD_secondCategoryTitle': 'Analyse d’audience',
      'secondCategoryTitle': 'Analyse de la performance',
      'OLD_secondCategoryText':
        'Les cookies statistiques permettent de mesurer l’utilisation et les performances du Site et d’en améliorer le fonctionnement.',
      'secondCategoryText':
        "Ces identifiants permettent de mesurer l'utilisation et les performances de nos services (à l'exclusion de la mesure d'audience anonyme) pour mieux nous adapter à vos attentes.",
      'OLD_thirdCategoryTitle': 'Publicité',
      'thirdCategoryTitle': 'Publicité personnalisée',
      'OLD_thirdCategoryText':
        'Les cookies publicitaires nous permettent, avec nos partenaires publicitaires, de vous proposer des publicités adaptées à vos centres d’intérêt. La désactivation de ces cookies n’aura pas d’impact sur le volume de publicités, mais uniquement sur leur pertinence.',
      'thirdCategoryText1':
        "Ces identifiants permettent, avec nos partenaires, de vous proposer des publicités adaptées à vos centres d'intérêt. Plus précisément, nous pourrons&nbsp;:",
      'thirdCategoryItem1':
        'adapter les contenus publicitaires selon la navigation de votre terminal ou les données personnelles que vous avez fournies, notamment en utilisant des fonctionnalités de ciblage (retargeting) pour vous présenter des publicités ou des contacts email suite à votre utilisation de nos services&nbsp;;',
      'thirdCategoryItem2': 'suivre la navigation ultérieure effectuée par votre terminal&nbsp;;',
      'thirdCategoryItem3': "adapter les espaces publicitaires aux préférences d'affichage de votre terminal&nbsp;;",
      'thirdCategoryItem4': 'adapter la présentation du service auquel mène un contenu publicitaire&nbsp;;',
      'thirdCategoryItem5': 'limiter le nombre de fois où vous voyez une publicité&nbsp;;',
      'thirdCategoryItem6': 'calculer les sommes dues aux acteurs de la chaîne de diffusion publicitaire&nbsp;;',
      'thirdCategoryItem7': "mesurer l'efficacité et l'impact d'une campagne publicitaire.",
      'thirdCategoryText2':
        "En désactivant ces identifiants, vous ne serez pas exposés à moins de publicités, mais à des publicités ne correspondant pas nécessairement à vos centres d'intérêts.",
      'OLD_vendorListButton': 'Afficher la liste des partenaires publicitaires',
      'vendorListButton': 'Liste des partenaires',
      'vendorTableTitle': 'Société',
      'cookiePolicyLabel': 'Afficher notre politique cookie',
      'declineButton': 'Continuer sans accepter',
      'refuseButton': 'Tout refuser',
      'acceptButton': 'Tout accepter',
      'saveAndCloseButton': 'Enregistrer et fermer',
      'partners': 'partenaires',
      'services': 'liste des supports',
      'confidentiality': 'Confidentialité',
      'cookies': 'Cookies',
      'cookiePolicy': 'notre politique cookie',
      'vivendiGroup': 'groupe Vivendi',
    },
    'cookiePolicyLink':
      'https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.canalplus.com%2Fcookies%2F&data=05%7C01%7Caccessibility%40canal-plus.com%7C823c1ca48f0e4aa5619308dadeb8aac0%7Cbf5c5de16a544091a72f90e32801628c%7C0%7C0%7C638067181957704103%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=%2F%2BOBIfCLeee%2BxzL4ssnB0cc3iyHR0g6Fv%2BPCECmayZM%3D&reserved=0',
    'partnersLink': 'https://static.canalplus.com/legal/cookies.html',
    'vivendiGroupLink':
      'https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.vivendi.com%2Fwp-content%2Fuploads%2F2022%2F11%2FVivendi-Organigramme-simplifie-au-30-septembre-2022-version-accessible.pdf&data=05%7C01%7Caccessibility%40canal-plus.com%7C823c1ca48f0e4aa5619308dadeb8aac0%7Cbf5c5de16a544091a72f90e32801628c%7C0%7C0%7C638067181957704103%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=h18CFEGAfLnvVqBZ6jHQ0%2BArXmAtDUznsj152kEoXHY%3D&reserved=0',
    'a11y': {
      'popinTitle': 'Groupe Canal Plus, le respect de votre vie privée est une priorité',
      'learnMore': 'En savoir plus et paramétrer vos consentements',
      'acceptButton': 'Accepter notre traitement des données',
      'declineButton': 'Continuer sans accepter notre traitement des données',
      'saveAndCloseButton': 'Enregistrer vos paramètres et continuer',
      'refuseButton': 'Refuser notre traitement des données',
      'vivendiGroupLink': 'Groupe Vivendi - nouvelle fenêtre',
      'partnersLink': 'Nos partenaires - nouvelle fenêtre',
      'newWindow': 'nouvelle fenêtre',
      'cookiesLink': 'Cookies - nouvelle fenêtre',
    },
    'customVendors': [
      { 'name': 'Vivendi', 'policyUrl': 'https://www.vivendi.com/footer/cookies-policy/' },
      { 'name': 'Dailymotion', 'policyUrl': 'https://www.dailymotion.com/legal/privacy' },
      { 'name': 'Gameloft', 'policyUrl': 'https://www.gameloft.com/fr/legal/showcase-cookie-policy' },
      { 'name': 'Editis', 'policyUrl': 'https://www.editis.com/gestion-des-cookies/' },
      { 'name': 'Vivendi Village', 'policyUrl': 'https://www.vivendi.com/footer/cookies-policy/' },
      {
        'name': 'Mediarithmics',
        'policyUrl': 'https://www.mediarithmics.com/fr-fr/content/charte-de-protection-des-donnees-personnelles',
      },
      { 'name': 'Betasérie', 'policyUrl': 'https://www.betaseries.com/conditions' },
      { 'name': 'Facebook', 'policyUrl': 'https://www.facebook.com/policies/cookies/' },
      { 'name': 'Mozoo', 'policyUrl': 'http://www.mozoo.fr/' },
      { 'name': 'Powerspace', 'policyUrl': 'https://powerspace.com/fr/privacy/' },
      { 'name': 'Snapchat', 'policyUrl': 'https://snap.com/fr-FR/cookie-policy' },
      { 'name': 'Adot', 'policyUrl': 'https://we-are-adot.com/privacy-policy/' },
      { 'name': 'CCM Benchmark', 'policyUrl': 'http://www.ccmbenchmark.com/donnees-personnelles?origin=s_institut' },
      { 'name': 'Emoteev', 'policyUrl': 'https://www.emoteev.io/fr/page-daccueil/' },
      { 'name': 'Horyzon', 'policyUrl': 'https://www.horyzon.com/' },
      { 'name': 'Pinterest', 'policyUrl': 'https://policy.pinterest.com/fr/privacy-policy' },
      {
        'name': 'Reworld',
        'policyUrl':
          'https://www.reworldmedia.com/wp-content/uploads/2020/09/Politique-donn%C3%A9es-personnelles-et-cookies.pdf',
      },
      { 'name': 'Tiktok', 'policyUrl': 'https://www.tiktok.com/legal/tiktok-website-cookies-policy?lang=fr' },
      { 'name': 'Tradedoubler', 'policyUrl': 'https://www.tradedoubler.com/fr/privacy-policy/' },
      { 'name': 'Twitter', 'policyUrl': 'https://help.twitter.com/fr/rules-and-policies/twitter-cookies' },
      { 'name': 'Freewheel', 'policyUrl': 'https://www.freewheel.com/privacy-policy-fr' },
      { 'name': 'Acast', 'policyUrl': 'https://www.acast.com/en/privacy' },
    ],
  },
  {
    'zones': ['pl'],
    'locale': 'pl',
    'privacyPopin': {
      'OLD_title': 'Ustawienia plików cookies na stronach serwisu CANAL+',
      'title': 'Dotyczy Twoich danych',
      'mainText':
        'Pliki cookies są stosowane w Serwisie CANAL+ między innymi w celu zagwarantowania jego poprawnego działania i dostosowania do Twoich potrzeb poprzez personalizację treści i reklam. Zawsze możesz wyłączyć pliki cookies według poniższych kategorii.',
      'firstParagraph':
        'Grupa CANAL+, jej spółki zależne (w tym CANAL+ Polska S.A. i członkowie %vivendiGroup%) oraz nasi %partners% (listę znajdziesz poniżej) używają plików cookies i podobnych technologii do zarządzania usługami i ich ulepszania oraz dostosowywania reklam do Twoich preferencji.',
      'learnMore':
        'Dowiedz się więcej i skonfiguruj wybrane ustawienia plików cookies. Możesz je aktualizować w dowolnym momencie w zakładce Ustawienia.',
      'OLD_firstCategoryTitle': 'Pliki cookies obowiązkowe dla poprawnego działania usługi.',
      'firstCategoryTitle': 'Poprawne działanie usługi (niezbędne)',
      'OLD_firstCategoryText':
        'Sesyjne pliki cookies Użytkownika, weryfikacyjne, uwierzytelniające, bezpieczeństwa oraz identyfikacyjne są konieczne dla poprawnego działania Serwisu CANAL+.',
      'firstCategoryText':
        'Te pliki cookies zapewniają działanie usługi, umożliwiając uwierzytelnianie użytkowników, bezpieczeństwo oraz (anonimowy) nadzór techniczny nad usługą. Nie można wyłączyć tego ustawienia.',
      'OLD_secondCategoryTitle': 'Mierzenie oglądalności',
      'secondCategoryTitle': 'Analiza wydajności',
      'OLD_secondCategoryText':
        'Statystyczne pliki cookies umożliwiają tworzenie anonimowych, zagregowanych statystyk w celu ulepszenia struktury i zawartości Serwisu CANAL+.',
      'secondCategoryText':
        'Te pliki cookies umożliwiają nam mierzenie wykorzystania oraz wydajności naszych usług (z wyłączeniem anonimowego pomiaru oglądalności) w celu lepszego dostosowania się do Twoich oczekiwań oraz zarządzania usługami.',
      'OLD_thirdCategoryTitle': 'Reklama',
      'thirdCategoryTitle': 'Spersonalizowana reklama',
      'OLD_thirdCategoryText':
        'Reklamowe pliki cookies pozwalają Partnerom na wyświetlanie reklam dostosowanych do Twoich zainteresowań. Wyłączenie tych plików cookies nie będzie miało wpływu na liczbę wyświetlanych reklam, ale na ich trafność.',
      'thirdCategoryText':
        'Reklamowe pliki cookie pozwalają nam, wraz z naszymi partnerami reklamowymi, oferować Ci reklamy dostosowane do Twoich zainteresowań, w oparciu o Twoje przeglądanie i profil oraz mierzyć ich oglądalność / skuteczność. Wyłączenie tych plików cookie nie będzie miało wpływu na liczbę reklam, a jedynie na ich trafność.',
      'thirdCategoryText1':
        'Te pliki cookies pozwalają nam oraz naszym partnerom oferować reklamy dostosowane do Twoich zainteresowań. Dokładniej oznacza to, że będziemy mogli:',
      'thirdCategoryItem1':
        'dostosowywać treści reklamowe do działań wykonywanych na Twoim urządzeniu lub do danych osobowych, które nam podałeś(-aś) w szczególności poprzez wykorzystanie funkcji targetowania reklam lub kontaktów e-mailowych na podstawie historii Twojego korzystania z naszych usług;',
      'thirdCategoryItem2': 'monitorować kolejne działania wykonywane na Twoim urządzeniu;',
      'thirdCategoryItem3': 'dostosowywać obszary reklamowe do preferencji wyświetleń na Twoim urządzeniu;',
      'thirdCategoryItem4': 'dostosowywać prezentację usług, z którymi powiązane są treści reklamowe;',
      'thirdCategoryItem5': 'ograniczyć liczbę wyświetlanych reklam;',
      'thirdCategoryItem6': 'obliczać kwoty należne podmiotom w łańcuchu dystrybucji reklam;',
      'thirdCategoryItem7': 'mierzyć skuteczność i wpływ kampanii reklamowych;',
      'thirdCategoryItem8':
        'analizować Twoje aktywności również jako niezalogowanego użytkownika na potrzeby retargetingu produktów lub usług pozostawionych w „porzuconym koszyku”.',
      'thirdCategoryText2':
        'Wyłączając te pliki cookies, nie będziesz otrzymywać mniejszej ilości materiałów reklamowych, ale będą pojawiały się reklamy nie zawsze odpowiadające Twoim zainteresowaniom.',
      'vendorListButton': 'Lista partnerów',
      'vendorTableTitle': 'Firma',
      'cookiePolicyLabel': 'Zobacz naszą politykę plików cookies',
      'declineButton': 'Odrzuć wszystko',
      'refuseButton': 'Odrzuć wszystko',
      'acceptButton': 'Akceptuj wszystko',
      'saveAndCloseButton': 'Zapisz i zamknij',
      'partners': 'partnerzy',
      'services': 'lista usług',
      'confidentiality': 'Ustawienia plików cookies',
      'cookies': 'Ustawienia plików cookies',
      'cookiePolicy': 'naszej polityki plików cookie',
      'vivendiGroup': 'grupy Vivendi',
    },
    'cookiePolicyLink': 'https://www.canalplus.com/pl/cookies/',
    'partnersLink': 'https://www.canalplus.com/pl/cookies/',
    'vivendiGroupLink':
      'https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.vivendi.com%2Fwp-content%2Fuploads%2F2022%2F11%2FVivendi-Organigramme-simplifie-au-30-septembre-2022-version-accessible.pdf&data=05%7C01%7Caccessibility%40canal-plus.com%7C823c1ca48f0e4aa5619308dadeb8aac0%7Cbf5c5de16a544091a72f90e32801628c%7C0%7C0%7C638067181957704103%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=h18CFEGAfLnvVqBZ6jHQ0%2BArXmAtDUznsj152kEoXHY%3D&reserved=0',
    'a11y': {
      'popinTitle': 'Dla Grupy Canal Plus poszanowanie Twojej prywatności jest priorytetem',
      'learnMore': 'Dowiedz się więcej i skonfiguruj swoje zgody',
      'acceptButton': 'Zgoda na przetwarzanie danych przez nas',
      'declineButton': 'Kontynuuj bez zgody na przetwarzanie danych przez nas',
      'saveAndCloseButton': 'Zapisz ustawienia i kontynuuj',
      'refuseButton': 'Rezygnacja z przetwarzania danych przez nas',
      'vivendiGroupLink': 'Grupy Vivendi - nowe okno',
      'partnersLink': 'Nasi partnerzy - nowe okno',
      'newWindow': 'nowe okno',
      'cookiesLink': 'Cookies - nowe okno',
    },
    'customVendors': [
      { 'name': 'Vivendi', 'policyUrl': 'https://www.vivendi.com/footer/cookies-policy/' },
      { 'name': 'Dailymotion', 'policyUrl': 'https://www.dailymotion.com/legal/privacy' },
      { 'name': 'Gameloft', 'policyUrl': 'https://www.gameloft.com/fr/legal/showcase-cookie-policy' },
      { 'name': 'Editis', 'policyUrl': 'https://www.editis.com/gestion-des-cookies/' },
      { 'name': 'Vivendi Village', 'policyUrl': 'https://www.vivendi.com/footer/cookies-policy/' },
      { 'name': 'WP', 'policyUrl': 'https://onas.wp.pl/poufnosc.html' },
      { 'name': 'RASP', 'policyUrl': 'https://polityka-prywatnosci.onet.pl/index.html' },
      { 'name': 'Interia', 'policyUrl': 'https://prywatnosc.interia.pl/polityka-prywatnosci' },
      { 'name': 'ZPR', 'policyUrl': 'https://rodo.grupazpr.pl/' },
      { 'name': 'Adtraction', 'policyUrl': 'https://adtraction.com/about-us/privacy-policy' },
      { 'name': 'Bankier', 'policyUrl': 'https://apps.bonnier.pl/img/polityka_plikow_cookies.pdf' },
      { 'name': 'BlueLead', 'policyUrl': 'https://bluepartner.eu/pl/dokumenty/' },
      { 'name': 'CityAds', 'policyUrl': 'https://cityads.com/main/cookiePolicy' },
      { 'name': 'eBroker', 'policyUrl': 'https://www.ebroker.pl/canal_plus_polityka_prywatnosci.pdf' },
      { 'name': 'Inis', 'policyUrl': 'https://www.inis.pl/polityka-prywatnosci/' },
      { 'name': 'LeadStar', 'policyUrl': 'https://leadstar.pl/politykaprywatnosci' },
      { 'name': 'Tradetracker', 'policyUrl': 'https://tradetracker.com/privacy-policy/' },
      { 'name': 'Glob360 (Natemat)', 'policyUrl': 'https://natemat.pl/info/polityka-prywatnosci   ' },
      { 'name': 'Antyweb', 'policyUrl': 'https://antyweb.pl/polityka-prywatnosci/' },
      { 'name': 'NaEkranie', 'policyUrl': 'https://naekranie.pl/polityka-prywatnosci ' },
      {
        'name': 'USŁUGI INTERNETOWO REKLAMOWE HDTV PIOTR GMEREK',
        'policyUrl': 'https://hdtvpolska.com/polityka-prywatnosci/',
      },
      { 'name': 'Filmweb', 'policyUrl': 'https://www.filmweb.pl/privacy' },
      {
        'name': 'Spider’s Web - PROBIZ PRZEMYSŁAW PAJĄK',
        'policyUrl': 'https://spidersweb.pl/polityka-prywatnosci-spiders-web/',
      },
      { 'name': 'TVN Media', 'policyUrl': 'https://konto.tvn.pl/pdf/polityka_prywatnosci' },
      { 'name': 'Mobile B2B', 'policyUrl': 'http://www.mobileb2b.pl/polityka-prywatnosci/' },
      {
        'name': 'Email Network',
        'policyUrl':
          'https://emailnetwork.pl/wp-content/uploads/2019/04/Polityka-prywatnosci-i-cookies-Email-Network.pdf',
      },
      { 'name': 'Justtag', 'policyUrl': 'https://www.justtag.com/privacy-policy.html' },
      {
        'name': 'Adrino Mobile',
        'policyUrl': 'https://adrino.pl/wp-content/uploads/2020/05/POLITYKA-PRYWATNOS%CC%81CI-Adrino-Mobile.pdf',
      },
      { 'name': 'Listonic', 'policyUrl': 'https://listonic.com/pl/polityka-prywatnosci/' },
      { 'name': 'NetSalesMedia', 'policyUrl': 'https://netsalesmedia.pl/polityka-prywatnosci.html' },
      { 'name': 'Agora', 'policyUrl': 'https://www.agora.pl/polityka-prywatnosci ' },
      { 'name': 'Grupa Interia', 'policyUrl': 'https://prywatnosc.interia.pl/polityka-prywatnosci' },
      { 'name': 'Scoop', 'policyUrl': 'https://nextclick.pl/privacy-policy' },
      {
        'name': 'Polsat Media Biuro Reklamy',
        'policyUrl': 'https://www.polsatmedia.pl/bin/acc6798ced78762703e9a13750f6467a.pdf',
      },
      { 'name': 'HTTPool Slovenia (Twitter)', 'policyUrl': 'https://www.httpool.pl/privacy/ ' },
      { 'name': 'Freewheel', 'policyUrl': 'https://www.freewheel.com/privacy-policy' },
      { 'name': 'Acast', 'policyUrl': 'https://www.acast.com/en/privacy' },
      { 'name': 'UseItBetter', 'policyUrl': 'https://help.useitbetter.com/en/terms-and-policies/privacy-policy.html' },
      { 'name': 'Microsoft Clarity', 'policyUrl': 'https://privacy.microsoft.com/pl-pl/privacystatement' },
    ],
  },
  {
    'zones': ['it'],
    'locale': 'it',
    'privacyPopin': {
      'OLD_title': 'Impostazioni dei cookie sui siti web di GROUPE CANAL+',
      'title': 'Informazioni sui tuoi dati',
      'mainText':
        'I cookie vengono inviati ai Siti di GROUPE CANAL+, che fa parte del gruppo VIVENDI, in particolare per garantirne il corretto funzionamento e per personalizzare la tua esperienza, i contenuti o la pubblicità. Se desideri, puoi disabilitare i cookie per categoria.',
      'firstParagraph':
        'Groupe CANAL+ e i suoi affiliati (membri del %vivendiGroup%) e %partners% utilizzano traccianti per gestire e migliorare i nostri servizi e offrirti pubblicità più pertinenti.',
      'secondParagraph':
        'Puoi configurare le tue scelte per finalità e aggiornarle in qualsiasi momento tramite la nostra sezione %cookies%.',
      'thirdParagraph':
        "Se hai effettuato l'accesso con il tuo account CANAL+, la scelta sarà valida per l'utilizzo e la navigazione della home su tutte le applicazioni e media digitali del gruppo Canal+ (elenco dei media).",
      'thirdParagraphUnlog':
        'La vostra scelta sarà valida solo sul dispositivo corrente. Se accedete al vostro account CANAL+, le vostre scelte espresse in modalità login prevarranno.',
      'learnMore': 'Maggiori informazioni e configurazione delle mie scelte',
      'OLD_firstCategoryTitle': 'Cookie necessari per il funzionamento',
      'firstCategoryTitle': 'Funzionamento del servizio (obbligatorio)',
      'OLD_firstCategoryText':
        "Si tratta di cookie di sessione dell'utente, di autenticazione e sicurezza strettamente necessari per il funzionamento del Sito.",
      'firstCategoryText':
        "Questi identificatori rendono possibile il funzionamento del servizio, consentendo l'autenticazione dell'utente, la sicurezza e la supervisione tecnica (anonima) del servizio. Non è possibile disattivare questa impostazione.",
      'OLD_secondCategoryTitle': 'Misurazione del pubblico',
      'secondCategoryTitle': 'Analisi di prestazione',
      'OLD_secondCategoryText':
        "I cookie statistici vengono utilizzati per misurare l'utilizzo e le prestazioni del Sito e per migliorarne il funzionamento.",
      'secondCategoryText':
        "Questi identificatori consentono di misurare l'utilizzo e le prestazioni dei nostri servizi (ad esclusione della misurazione anonima del pubblico) per adattarsi al meglio alle tue aspettative.",
      'OLD_thirdCategoryTitle': 'Pubblicità',
      'thirdCategoryTitle': 'Pubblicità personalizzata',
      'OLD_thirdCategoryText':
        'I cookie pubblicitari consentono ai nostri partner pubblicitari di proporre pubblicità su misura per i tuoi interessi. La disattivazione di questi cookie non avrà alcun impatto sul volume degli annunci, ma solo sulla loro rilevanza.',
      'thirdCategoryText1':
        'Questi identificatori ci consentono, insieme ai nostri partner, di offrirti pubblicità su misura per i tuoi interessi. Più precisamente, saremo in grado di:',
      'thirdCategoryItem1':
        'adattare i contenuti pubblicitari in base alla navigazione del tuo dispositivo o ai dati personali che ci hai fornito, in particolare utilizzando funzionalità di retargeting per presentarti pubblicità o contatti email dopo aver usufruito dei nostri servizi;',
      'thirdCategoryItem2': 'seguire la successiva navigazione effettuata dal tuo terminale;',
      'thirdCategoryItem3': 'adattare gli spazi pubblicitari alle preferenze di visualizzazione del tuo terminale;',
      'thirdCategoryItem4': 'adattare la presentazione del servizio a cui rimanda un contenuto pubblicitario;',
      'thirdCategoryItem5': 'limitare il numero di volte in cui vedi un annuncio pubblicitario;',
      'thirdCategoryItem6': 'calcolare gli importi spettanti agli attori della filiera pubblicitaria;',
      'thirdCategoryItem7': "misurare l'efficacia e l'impatto di una campagna pubblicitaria.",
      'thirdCategoryText2':
        'Disattivando questi identificatori, non visualizzerai un minor numero di annunci pubblicitari, ma annunci non necessariamente pertinenti ai tuoi interessi.',
      'OLD_vendorListButton': "Visualizza l'elenco dei partner pubblicitari",
      'vendorListButton': 'Elenco dei partner',
      'vendorTableTitle': 'Azienda',
      'cookiePolicyLabel': 'Visualizza la nostra informativa sui cookie',
      'declineButton': 'Rifiuta tutto',
      'refuseButton': 'Rifiuta tutto',
      'acceptButton': 'Accetta tutto',
      'saveAndCloseButton': 'Salva e chiudi',
      'partners': 'partner',
      'services': 'elenco dei media',
      'confidentiality': 'Privacy',
      'cookies': 'Cookies',
      'cookiePolicy': 'la nostra informativa sui cookie',
      'vivendiGroup': 'gruppo Vivendi',
    },
    'cookiePolicyLink': 'https://static.canalplus.com/legal/cookies.html',
    'partnersLink': 'https://static.canalplus.com/legal/cookies.html',
    'vivendiGroupLink':
      'https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.vivendi.com%2Fwp-content%2Fuploads%2F2022%2F11%2FVivendi-Organigramme-simplifie-au-30-septembre-2022-version-accessible.pdf&data=05%7C01%7Caccessibility%40canal-plus.com%7C823c1ca48f0e4aa5619308dadeb8aac0%7Cbf5c5de16a544091a72f90e32801628c%7C0%7C0%7C638067181957704103%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=h18CFEGAfLnvVqBZ6jHQ0%2BArXmAtDUznsj152kEoXHY%3D&reserved=0',
    'a11y': {
      'popinTitle': 'Gruppo Canal Plus, il rispetto della sua privacy è una priorità',
      'learnMore': 'Per saperne di più e impostare i tuoi consensi',
      'acceptButton': 'Accettazione del nostro trattamento dei dati',
      'declineButton': 'Continuare senza accettare il nostro trattamento dei dati',
      'saveAndCloseButton': 'Salvare le impostazioni e continuare',
      'refuseButton': 'Rinuncia al nostro trattamento dei dati',
      'vivendiGroupLink': 'Gruppo Vivendi - nuova finestra',
      'partnersLink': 'I nostri partner - nuova finestra',
      'newWindow': 'nuova finestra',
      'cookiesLink': 'Cookies - nuova finestra',
    },
    'customVendors': [],
  },
  {
    'default': true,
    'zones': [
      'bf',
      'bi',
      'bj',
      'bl',
      'cd',
      'cf',
      'cg',
      'ch',
      'ci',
      'cm',
      'cv',
      'dj',
      'fr',
      'ga',
      'gf',
      'gh',
      'gm',
      'gn',
      'gp',
      'gq',
      'gw',
      'mc',
      'mf',
      'mg',
      'ml',
      'mq',
      'mr',
      'mu',
      'nc',
      'ne',
      'ng',
      'pl',
      're',
      'rw',
      'sl',
      'sn',
      'tg',
      'td',
      'wf',
      'yt',
      'pf',
    ],
    'locale': 'en',
    'privacyPopin': {
      'OLD_title': 'Cookie setting on GROUPE CANAL+ websites',
      'title': 'About your data',
      'mainText':
        'We use cookies on websites of GROUPE CANAL+, which is part of VIVENDI group, in order to ensure their proper functioning and to personalize your experience, contents or adverts. You may, if you wish, deactivate categories of cookies.',
      'firstParagraph':
        'CANAL+ Group and its subsidiaries and our %partners%, including %vivendiGroup% affiliates, use tracers to measure the use and the performance of our services and to offer you more relevant advertisements on the basis of your navigation and your profile.',
      'OLD_firstParagraph':
        'CANAL+ Group and its subsidiaries (members of the %vivendiGroup%) and our %partners% use tracers to manage and improve our services and offer you more relevant ads.',
      'secondParagraph':
        'You can learn more about the use of your data, set your choices by purpose and update them at any time via our %cookies% section.',
      'OLD_secondParagraph':
        'You can configure your choices by purpose and update them at any time via our %confidentiality% section.',
      'thirdParagraph':
        'Because you are connected to your CANAL+ account, your choice will be valid on all the digital media of the CANAL+ Group connected to this account.',
      'OLD_thirdParagraph':
        'Because you are logged in to your CANAL+ account, your choice will apply to all of Groupe CANAL+’s digital media connected to your account.',
      'thirdParagraphUnlog':
        'Your choice will only be valid on the device you are currently using. If you are connected to your CANAL+ account, your choices expressed in connected mode will prevail.',
      'OLD_thirdParagraphUnlog':
        'Your choice will be valid only on the current device. If you log in to your CANAL+ account, your choices expressed in logged-in mode will prevail.',
      'learnMore': 'Learn more and configure my choices',
      'OLD_firstCategoryTitle': 'Strictly necessary',
      'firstCategoryTitle': 'Functioning of service (required)',
      'OLD_firstCategoryText':
        'These are user session, authentication and security cookies, which are strictly necessary to the website functioning.',
      'firstCategoryText':
        'These identifiers make it possible to ensure functioning of the service, allowing user authentication, security and technical supervision (anonymous) of the service. You cannot disable this setting.',
      'OLD_secondCategoryTitle': 'Audience measurements',
      'secondCategoryTitle': 'Performance analysis',
      'OLD_secondCategoryText':
        'Cookies used for statistical purposes enable us to measure the use and performance of our website and to improve its functioning.',
      'secondCategoryText':
        'These identifiers make it possible to measure the use and performance of our services (excluding anonymous audience measurement) in order to better adapt to your expectations.',
      'OLD_thirdCategoryTitle': 'Advertising',
      'thirdCategoryTitle': 'Personalised ads',
      'OLD_thirdCategoryText':
        'Advertising cookies enable us and our advertising partners to provide you with adverts which are adapted to your interests. Deactivating those cookies will not have an impact on the adverts volume, but only on their relevance.',
      'thirdCategoryText':
        'Advertising cookies enable us and our advertising partners to provide you with advertisements which are adapted to your interests, according to your navigation and profile, and to measure their efficacity. Deactivating those cookies will not have an impact on the amount of advertisements, but only on their relevance.',
      'thirdCategoryText1':
        'These identifiers allow us, together with our partners, to offer you ads tailored to your areas of interest. More precisely, we will be able to:',
      'thirdCategoryItem1':
        'adapt the advertising content according to browsing on your terminal or the personal data you have provided, in particular by using targeting functions (retargeting) to offer you ads or email contacts following your use of our services;',
      'thirdCategoryItem2': 'follow subsequent browsing carried out on your terminal;',
      'thirdCategoryItem3': 'adapt advertising spaces to the display preferences of your terminal;',
      'thirdCategoryItem4': 'adapt presentation of the service to which advertising content leads;',
      'thirdCategoryItem5': 'limit the number of times you see an ad;',
      'thirdCategoryItem6': 'calculate the sums due to the players in the advertising distribution chain;',
      'thirdCategoryItem7': 'measure the effectiveness and impact of an advertising campaign.',
      'thirdCategoryText2':
        'By disabling these identifiers, you will not be exposed to fewer ads, but rather to ads that do not necessarily correspond to your interests.',
      'OLD_vendorListButton': 'Show list of advertising partners',
      'vendorListButton': 'List of partners',
      'vendorTableTitle': 'Company',
      'cookiePolicyLabel': 'Show our cookie policy',
      'declineButton': 'Refuse all',
      'refuseButton': 'Refuse all',
      'acceptButton': 'Accept all',
      'saveAndCloseButton': 'Save and close',
      'partners': 'partners',
      'services': 'list of services',
      'confidentiality': 'Confidentiality',
      'cookies': 'Cookies',
      'cookiePolicy': 'our cookie policy',
      'vivendiGroup': 'Vivendi group',
    },
    'cookiePolicyLink':
      'https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.canalplus.com%2Fcookies%2F&data=05%7C01%7Caccessibility%40canal-plus.com%7C823c1ca48f0e4aa5619308dadeb8aac0%7Cbf5c5de16a544091a72f90e32801628c%7C0%7C0%7C638067181957704103%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=%2F%2BOBIfCLeee%2BxzL4ssnB0cc3iyHR0g6Fv%2BPCECmayZM%3D&reserved=0',
    'partnersLink': 'https://static.canalplus.com/legal/cookies.html',
    'vivendiGroupLink':
      'https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.vivendi.com%2Fwp-content%2Fuploads%2F2022%2F11%2FVivendi-Organigramme-simplifie-au-30-septembre-2022-version-accessible.pdf&data=05%7C01%7Caccessibility%40canal-plus.com%7C823c1ca48f0e4aa5619308dadeb8aac0%7Cbf5c5de16a544091a72f90e32801628c%7C0%7C0%7C638067181957704103%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=h18CFEGAfLnvVqBZ6jHQ0%2BArXmAtDUznsj152kEoXHY%3D&reserved=0',
    'a11y': {
      'popinTitle': 'Canal Plus Group, respecting your privacy is a priority',
      'learnMore': 'Learn more and configure your consents',
      'acceptButton': 'Agree to our data processing',
      'declineButton': 'Continue without accepting our data processing',
      'saveAndCloseButton': 'Save your settings and continue',
      'refuseButton': 'Opt out of our data processing',
      'vivendiGroupLink': 'Vivendi Group - new window',
      'partnersLink': 'Our partners - new window',
      'newWindow': 'new window',
      'cookiesLink': 'Cookies - new window',
    },
    'customVendors': [
      { 'name': 'Vivendi', 'policyUrl': 'https://www.vivendi.com/footer/cookies-policy/' },
      { 'name': 'Dailymotion', 'policyUrl': 'https://www.dailymotion.com/legal/privacy' },
      { 'name': 'Gameloft', 'policyUrl': 'https://www.gameloft.com/en/legal/showcase-cookie-policy' },
      { 'name': 'Editis', 'policyUrl': 'https://www.editis.com/gestion-des-cookies/' },
      { 'name': 'Vivendi Village', 'policyUrl': 'https://www.vivendi.com/footer/cookies-policy/' },
      {
        'name': 'Mediarithmics',
        'policyUrl': 'https://www.mediarithmics.com/en-us/content/privacy-policy',
      },
      { 'name': 'Betasérie', 'policyUrl': 'https://www.betaseries.com/conditions' },
      { 'name': 'Facebook', 'policyUrl': 'https://www.facebook.com/policies/cookies/' },
      { 'name': 'Mozoo', 'policyUrl': 'http://www.mozoo.fr/' },
      { 'name': 'Powerspace', 'policyUrl': 'https://powerspace.com/fr/privacy/' },
      { 'name': 'Snapchat', 'policyUrl': 'https://snap.com/en-US/cookie-settings' },
      { 'name': 'Adot', 'policyUrl': 'https://we-are-adot.com/privacy-policy/' },
      { 'name': 'CCM Benchmark', 'policyUrl': 'http://www.ccmbenchmark.com/donnees-personnelles?origin=s_institut' },
      { 'name': 'Emoteev', 'policyUrl': 'https://www.emoteev.io/' },
      { 'name': 'Horyzon', 'policyUrl': 'https://www.horyzon.com/' },
      { 'name': 'Pinterest', 'policyUrl': 'https://policy.pinterest.com/en/privacy-policy' },
      {
        'name': 'Reworld',
        'policyUrl':
          'https://www.reworldmedia.com/wp-content/uploads/2020/09/Politique-donn%C3%A9es-personnelles-et-cookies.pdf',
      },
      { 'name': 'Tiktok', 'policyUrl': 'https://www.tiktok.com/legal/tiktok-website-cookies-policy' },
      { 'name': 'Tradedoubler', 'policyUrl': 'https://www.tradedoubler.com/en/privacy-policy/' },
      { 'name': 'Twitter', 'policyUrl': 'https://help.twitter.com/en/rules-and-policies/twitter-cookies' },
      { 'name': 'Freewheel', 'policyUrl': 'https://www.freewheel.com/privacy-policy' },
      { 'name': 'Acast', 'policyUrl': 'https://www.acast.com/en/privacy' },
    ],
  },
];

export const getLangConfig = (choosenZone, choosenLocale) => {
  const filteredConfigs = configs.filter((el) => el.zones.includes(choosenZone));

  if (filteredConfigs.length > 0) {
    const configForZoneAndLocale = filteredConfigs.filter((el) => el.locale === choosenLocale);
    return configForZoneAndLocale.length > 0 ? configForZoneAndLocale[0] : filteredConfigs[0]; // Default is any locale for this zone
  }

  // If we haven't found any config for the choosenZone, we need to try by locale
  const configForLocale = filteredConfigs.filter((el) => el.locale === choosenLocale);
  return configForLocale.length > 0 ? configForLocale[0] : configs.filter((locale) => locale.default === true)[0]; // Default config is "EN" locale
};
