import {
  setAdsOptOut,
  setAnonymous,
  setStatsOptOut,
  syncUserPrefWithIframe,
  updateDeviceIdCookieAction,
} from '../Actions/PrivacyActions';
import { addLevelInfo, enrichDataLayer } from '../DataLayer/EnrichDataLayer';
import * as tealium from '../Integrations/Tealium';
import {
  COOKIE_NAME_ADS,
  COOKIE_NAME_ANONYMOUS,
  COOKIE_NAME_STATS,
  DEFAULT_COOKIE_DURATION,
} from '../PrivacyManager/Constants';
import {
  generateUID,
  isPassAuthenticated,
  isThirdPartyWebsite,
  isUserCentricConsentModeActivated,
  setCookie,
  writeCookie,
} from '../Utils';

export const sendPageView = async (trackingObject, initParam) => {
  const store = window.ttStore;
  const state = store.getState();
  if (!state.anonymousAllowed) {
    return;
  }

  // pageName field is deprecated, we're keeping it for retrocompatibility
  const name = trackingObject?.name || trackingObject?.data?.pageName || trackingObject?.data?.page_name;

  // Name field is optionnal if there is a pageName provided in data (see below)
  if (name) {
    const init = typeof initParam !== 'undefined' ? initParam : false; // Not an init by default

    if (!trackingObject.name) {
      trackingObject.name = name;
    }

    // We need to keep the dataLayer for the first event because it has been initialized with some information
    // See function initDataLayerWithSessionStart
    if (!ttDataLayer.isFirstEventSinceLoad()) {
      ttDataLayer.reset();
    }

    // Add level in props. This is specific to page view.
    addLevelInfo(ttDataLayer, trackingObject);

    enrichDataLayer(store, trackingObject);

    await tealium.sendTracking(store, ttDataLayer);

    if (state.config.logs) {
      ttDataLayer.printDataLayerInConsole();
    }

    // Add the tracking to queue because we need to trace every action for async scripts (DTM & queuemanager)
    if (!init && typeof queueManagerForLoadEvent !== 'undefined' /* Some entrypoints don't use this feature */) {
      // Exec functions in the queue (mostly from the DTM)
      try {
        trackingQueue.push(trackingObject);
        trackingQueue = trackingQueue.slice(-1); // Only keep the last element of navigation
        queueManagerForLoadEvent.exec();
      } catch (e) {
        console.error(`Error in queueManagerForLoadEvent. ${e}`);
      }
    }

    // We need to reset from the dataLayer the first session property AFTER having sent the data
    ttDataLayer.unsetFirstEventSinceLoad();
    ttDataLayer.unsetFirstEventSinceSessionStart();

    store.dispatch({ type: 'SEND_PAGE_VIEW' }); // Log, doesn't actually change state
  } else {
    console.error('Tracking Object not supplied on sendPageView function');
  }
};

export const sendEvent = async (event) => {
  const store = window.ttStore;
  const state = store.getState();
  if (!state.anonymousAllowed) {
    return;
  }

  if (event && event.name) {
    ttDataLayer.reset();

    ttDataLayer.add('event_name', event.name);
    ttDataLayer.add('linkName', event.name);

    // Check if there is a callback, set it to null otherwise
    const callback = event.callback && {}.toString.call(event.callback) === '[object Function]' ? event.callback : null;

    // If there are props and eVar to override
    event.data = event.data ? event.data : null;

    // Add events to dataLayer
    if (event.data) {
      const events = event.data.events;
      if (events) {
        ttDataLayer.addEvent(events);
      }
    }

    ttDataLayer.add('eVar11', event.name); // We need to manually populate evar11
    ttDataLayer.addEvent('event11');

    if (event.type) {
      ttDataLayer.add('linkType', event.type.toLowerCase());
    } else {
      ttDataLayer.add('linkType', 'custom');
    }

    enrichDataLayer(store, event);

    await tealium.sendEvent(ttDataLayer, callback);

    if (store.getState().config.logs) ttDataLayer.printDataLayerInConsole();
    store.dispatch({ type: 'SEND_EVENT' }); // Log, doesn't actually change state
  } else {
    console.error('Event Object not supplied on sendEvent function');
  }
};

const waitForUserConsent = (callback) => {
  const store = window.ttStore;
  callback(store.getState());
};

// Write cookie session on the main domain (in addition to the iframe's cookies)
export const storeUserChoiceOnTopDomainCookie = (store) => {
  const data = store.getState();

  // Extend the cookie duration only if the host domain is .canalplus.com, to be able to use it
  // to limit createToken calls in prospect mode
  if (!isThirdPartyWebsite()) {
    setCookie(COOKIE_NAME_ADS, data.adsAllowed, DEFAULT_COOKIE_DURATION);
    setCookie(COOKIE_NAME_STATS, data.statsAllowed, DEFAULT_COOKIE_DURATION);
    setCookie(COOKIE_NAME_ANONYMOUS, data.anonymousAllowed, DEFAULT_COOKIE_DURATION);
  } else {
    // If the domain is not .canalplus.com, write these cookies only for the current session
    writeCookie(COOKIE_NAME_ADS, data.adsAllowed);
    writeCookie(COOKIE_NAME_STATS, data.statsAllowed);
    writeCookie(COOKIE_NAME_ANONYMOUS, data.anonymousAllowed);
  }
};

// CANAL+ respects the GDPR regulation
// We add the tracking ONLY if the user expressed their consent with a clic
export const initAfterUserConsent = (store, hasAlreadyConsented = false) => {
  const state = store.getState();

  // Replace "empty" function defined on the window's object by the host with new one that actually really send tracking or data about user consent
  // Only if anonymous measurement is allowed! Otherwise the sendPageView and sendEvent functions should still be disabled
  if (state.anonymousAllowed) {
    window.sendPageView = sendPageView;
    window.sendEvent = sendEvent;
  }
  window.waitForUserConsent = waitForUserConsent;

  syncUserPrefWithIframe(store);
  // Write cookie session on the main domain (in addition to the iframe's cookies)
  storeUserChoiceOnTopDomainCookie(store);

  try {
    const { VID } = state;
    let { trackingKey } = state;
    const newTrackingKey = generateUID();

    // If trackingKey is undefined, which could be dramatic for analytics
    if (trackingKey === 'undefined' || trackingKey === '') {
      trackingKey = newTrackingKey;
      store.dispatch({ type: 'UPDATE_TRACKING_KEY', trackingKey });
    }

    if (isPassAuthenticated() && isUserCentricConsentModeActivated() && hasAlreadyConsented) {
      let needsIframeSync = false;
      if (window.passJSON?.analytics !== undefined && window.passJSON?.analytics !== state.statsAllowed) {
        setStatsOptOut(store, window.passJSON?.analytics);
        needsIframeSync = true;
      }
      if (window.passJSON?.trackingPub !== undefined && window.passJSON?.trackingPub !== state.adsAllowed) {
        setAdsOptOut(store, window.passJSON?.trackingPub);
        needsIframeSync = true;
      }
      if (
        window.passJSON?.anonymousTracking !== undefined &&
        window.passJSON?.anonymousTracking !== state.anonymousAllowed
      ) {
        setAnonymous(store, window.passJSON?.anonymousTracking);
        needsIframeSync = true;
      }
      if (needsIframeSync) {
        syncUserPrefWithIframe(store);
        storeUserChoiceOnTopDomainCookie(store);
      }
    }

    // We need to be sure we can store some data in cookies first
    if (state.statsAllowed && state.anonymousAllowed) {
      // If there is a desynchro between what is stored in the cookie and the user's login status,
      // it means that the user has logout!
      // We need to update deviceId cookie with the correct authenticated flag and renew trackingKey.
      if (state.authenticatedFlagFromCookie === '1' && isPassAuthenticated() === false) {
        store.dispatch({ type: 'UPDATE_TRACKING_KEY', trackingKey: newTrackingKey });
        store.dispatch({ type: 'UPDATE_AUTHENTICATE_FLAG', authenticatedFlagFromCookie: '0' });
        updateDeviceIdCookieAction(VID, '0', newTrackingKey);
      } else {
        // In all other case, we update the deviceId cookie but we keep the actual trackingKey
        store.dispatch({
          type: 'UPDATE_AUTHENTICATE_FLAG',
          authenticatedFlagFromCookie: isPassAuthenticated() ? '1' : '0',
        });
        updateDeviceIdCookieAction(VID, isPassAuthenticated() ? '1' : '0', trackingKey);
      }
    }

    execTrackingQueueAndDispatchConsent(store);
  } catch (e) {
    console.log(e);
    execTrackingQueueAndDispatchConsent(store);
  }

  store.dispatch({ type: 'SEND_TRACKING_AFTER_CONSENT' }); // It's just a log. No state diff
};

const execTrackingQueueAndDispatchConsent = (store) => {
  const state = store.getState();

  if (typeof trackingQueue !== 'undefined' && trackingQueue && trackingQueue.length > 0) {
    // ensure that the dataLayer is reset before sending the page view to avoid duplicate data inside payload
    ttDataLayer.reset();
    // Send all the tracking that has been stored in the queue
    trackingQueue.forEach((item) => sendPageView(item, true));
    trackingQueue = [];
  }

  if (typeof eventQueue !== 'undefined' && eventQueue && eventQueue.length > 0) {
    eventQueue.forEach((item) => sendEvent(item));
  } // We don't reset the queue because it's used by the QueueManager.

  // Fire callback after the user chooses to consent
  if (typeof consentCallbackQueue !== 'undefined' && consentCallbackQueue && consentCallbackQueue.length > 0) {
    consentCallbackQueue.forEach((item) => item.call(null, state));
  }

  document.dispatchEvent(new CustomEvent('userChangedConsent', { detail: state }));
};
