import {
  BRAND_CANALBOX,
  BRAND_MMC,
  BRAND_MYCANAL,
  BRAND_TIMVISION,
  BRAND_TVCARAIBES,
  DATA_DOMAIN,
  PATH_CANALBOX_LOCAL,
  PATH_CANALBOX_PROD,
  PATH_MMC_LOCAL,
  PATH_MMC_PROD,
  PATH_PANTON_FONT_LOCAL,
  PATH_PANTON_FONT_PROD,
  PATH_TVCARAIBES_LOCAL,
  PATH_TVCARAIBES_PROD,
  THEME_CANALBOX,
  THEME_DEFAULT,
  THEME_MMC,
  THEME_TIMVISION,
  THEME_TVCARAIBES,
} from '../../PrivacyManager/Constants';
import { isPassAuthenticated, isPassIncluded, isUserCentricConsentModeActivated } from '../../Utils';

const getTheme = (brand) => {
  switch (brand) {
    case BRAND_CANALBOX:
      return THEME_CANALBOX;
    case BRAND_MMC:
      return THEME_MMC;
    case BRAND_TIMVISION:
      return THEME_TIMVISION;
    case BRAND_TVCARAIBES:
      return THEME_TVCARAIBES;
    case BRAND_MYCANAL:
    default:
      return THEME_DEFAULT;
  }
};

const getLogo = (brand) => {
  switch (brand) {
    case BRAND_CANALBOX: {
      const canalBoxLogoSrc = __LOCAL__ ? PATH_CANALBOX_LOCAL : `https://${DATA_DOMAIN}${PATH_CANALBOX_PROD}`;
      return `<img class="logo" src="${canalBoxLogoSrc}" alt="CANALBOX">`;
    }
    case BRAND_MMC: {
      const mmcLogoSrc = __LOCAL__ ? PATH_MMC_LOCAL : `https://${DATA_DOMAIN}${PATH_MMC_PROD}`;
      return `<img class="logo" src="${mmcLogoSrc}" alt="Ma Maison CANALBOX">`;
    }
    case BRAND_TIMVISION:
      return `<img class="logo" src="https://thumb.prod.front.tim.cptech.pro/static/TIMVISION_white.svg" alt="TIMVISION">`;
    case BRAND_TVCARAIBES: {
      const tvCaraibesLogoSrc = __LOCAL__ ? PATH_TVCARAIBES_LOCAL : `https://${DATA_DOMAIN}${PATH_TVCARAIBES_PROD}`;
      return `<img class="logo" src="${tvCaraibesLogoSrc}" alt="TVCaraibes">`;
    }
    case BRAND_MYCANAL:
    default:
      return '<img class="canalplus-logo" src="https://thumb.canalplus.pro/static/canalplus-logo.svg" alt="CANAL+">'; /* TODO: delete canalplus-logo class once CANALBOX uses the brand to switch logos */
  }
};

const addPantonFont = (classnames = []) => {
  classnames.forEach((classname) => {
    const elements = document.getElementsByClassName(classname);
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].style.fontFamily = 'Panton Italic';
    }
  });
};

export const renderPopin = (currentLang, currentBrand) => {
  if (currentBrand === BRAND_TVCARAIBES) {
    const pantonFontSrc = __LOCAL__ ? PATH_PANTON_FONT_LOCAL : `https://${DATA_DOMAIN}${PATH_PANTON_FONT_PROD}`;
    const pantonFont = new FontFace('Panton Italic', `url(${pantonFontSrc}) format('opentype')`);
    pantonFont
      .load()
      .then((loadedFont) => {
        document.fonts.add(loadedFont);
        document.getElementById('pm-title').style.fontFamily = 'Panton Italic';
        addPantonFont(['pm-head-text', 'pm-optout-description']);
      })
      .catch((e) => console.error('Something went wrong with the load of the font: ', e));
  }

  const firstParagraph = currentLang.privacyPopin.firstParagraph
    .replace(
      '%partners%',
      `<a class="paragraph-link pm-focusable" target="_blank" rel="noreferrer" id="open-partners" href="${currentLang.partnersLink}" aria-label="${currentLang.a11y.partnersLink}">${currentLang.privacyPopin.partners}</a>`
    )
    .replace(
      '%vivendiGroup%',
      `<a class="paragraph-link pm-focusable" target="_blank" rel="noreferrer" id="vivendi-group" href="${currentLang.vivendiGroupLink}" aria-label="${currentLang.a11y.vivendiGroupLink}">${currentLang.privacyPopin.vivendiGroup}</a>`
    );
  const secondParagraph =
    currentLang?.privacyPopin?.secondParagraph?.replace(
      '%cookies%',
      `<a class="paragraph-link pm-focusable" target="_blank" rel="noreferrer" id="cnil-know-more-link" href="${currentLang.cookiePolicyLink}" aria-label="${currentLang.a11y.cookiesLink}">${currentLang.privacyPopin.cookies}</a>`
    ) || '';
  const thirdParagraph =
    (isPassIncluded() && isPassAuthenticated() && isUserCentricConsentModeActivated()
      ? currentLang.privacyPopin.thirdParagraph &&
        `<p class="pm-head-text">${currentLang.privacyPopin.thirdParagraph}</p>`
      : currentLang.privacyPopin.thirdParagraphUnlog &&
        `<p class="pm-head-text">${currentLang.privacyPopin.thirdParagraphUnlog}</p>`) || '';

  const logo = getLogo(currentBrand);
  const theme = getTheme(currentBrand);

  const thirdCategoryItemsHTML = Object.entries(currentLang.privacyPopin)
    .filter(([key]) => key.startsWith('thirdCategoryItem'))
    .map(([key, value]) => `<li id='${key}'>${value}</li>`)
    .join('');

  return `
    <div id="pm-popin" data-theme=${theme}>
      <div id="pm-overlay">
        <div class="pm-popin-content" id="popin-content" tabindex="-1" role="dialog" aria-modal="true" aria-label="${currentLang.a11y.popinTitle}">
          <div class="pm-header">
            ${logo}
          </div>
          <div class="pm-body">
            <h1 id="pm-title">${currentLang.privacyPopin.title}</h1>
            <p class="pm-head-text">${firstParagraph}</p>
            <p class="pm-head-text">${secondParagraph}</p>
            ${thirdParagraph}
            <button class="pm-toggle-button pm-focusable" id="pm-optout-display" aria-label="${currentLang.a11y.learnMore}"><span class="pm-toggle-button-text">${currentLang.privacyPopin.learnMore}</span>
              <svg class="arrow optout-arrow" id="optout-arrow-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                <path d="M12.72 19.231 L 5.178 11.69 a 1.185 1.185 0 1 1 1.676 -1.676 l 5.866 5.867 5.867 -5.867 a 1.185 1.185 0 0 1 1.676 1.677 l -7.542 7.542 z"/>
              </svg>
              <svg class="arrow optout-arrow" id="optout-arrow-up" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                <path d="M12.72 6.769 L 5.178 14.31 a 1.185 1.185 0 1 0 1.676 1.676 l 5.866 -5.867 5.867 5.867 a 1.185 1.185 0 0 0 1.676 -1.677 l -7.542 -7.542 z"/>
              </svg>
            </button>
            <div id="pm-optout-block">
              <div class="pm-optout">
                <div class="pm-optout-description">
                  <h2 class="pm-optout-title">${currentLang.privacyPopin.firstCategoryTitle}</h2>
                  <div class="pm-optout-text">
                    <p>
                      ${currentLang.privacyPopin.firstCategoryText}
                    </p>
                  </div>
                </div>
                <div class="pm-switch">
                  <label class="pm-switch-label" for="cb-necessary-cookies">
                    <input id="cb-necessary-cookies" class="pm-switch-checkbox" type="checkbox" disabled checked>
                    <span class="pm-switch-toggle"></span>
                  </label>
                </div>
              </div>
              <div class="pm-optout">
                <div class="pm-optout-description">
                  <label class="pm-optout-title-label" for="cb-optout-stats">
                    <h2 class="pm-optout-title">${currentLang.privacyPopin.secondCategoryTitle}</h2>
                  </label>
                  <div class="pm-optout-text">
                    <p>
                      ${currentLang.privacyPopin.secondCategoryText}
                    </p>
                  </div>
                </div>
                <div class="pm-switch">
                  <label class="pm-switch-label" for="cb-optout-stats">
                    <input id="cb-optout-stats" class="pm-switch-checkbox" type="checkbox">
                    <span class="pm-switch-toggle"></span>
                  </label>
                </div>
              </div>
              <div class="pm-optout">
                <div class="pm-optout-description">
                  <label class="pm-optout-title-label" for="cb-optout-ads">
                    <h2 class="pm-optout-title">${currentLang.privacyPopin.thirdCategoryTitle}</h2>
                  </label>
                  <div class="pm-optout-text">
                    <p>
                      ${currentLang.privacyPopin.thirdCategoryText1}
                    </p>
                    <ul>
                      ${thirdCategoryItemsHTML}
                    </ul>
                    <p>
                      ${currentLang.privacyPopin.thirdCategoryText2}
                    </p>
                    <div class="vendors-container">
                      <button class="pm-toggle-button pm-focusable" id="pm-ads-vendors"><span class="pm-toggle-button-text">${currentLang.privacyPopin.vendorListButton}</span>
                        <svg class="arrow vendor-arrow" id="vendor-arrow-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                          <path d="M12.72 19.231 L 5.178 11.69 a 1.185 1.185 0 1 1 1.676 -1.676 l 5.866 5.867 5.867 -5.867 a 1.185 1.185 0 0 1 1.676 1.677 l -7.542 7.542 z"/>
                        </svg>
                        <svg class="arrow vendor-arrow" id="vendor-arrow-up" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                          <path d="M12.72 6.769 L 5.178 14.31 a 1.185 1.185 0 1 0 1.676 1.676 l 5.866 -5.867 5.867 5.867 a 1.185 1.185 0 0 0 1.676 -1.677 l -7.542 -7.542 z"/>
                        </svg>
                      </button>
                      <div id="pm-vendor-list"></div>
                    </div>
                  </div>
                </div>
                <div class="pm-switch">
                    <label class="pm-switch-label" for="cb-optout-ads">
                      <input id="cb-optout-ads" class="pm-switch-checkbox" type="checkbox">
                      <span class="pm-switch-toggle"></span>
                    </label>
                  </div>
              </div>
            </div>
          </div>
          <div class="pm-footer">
            <div class="footer-btn-container" id="save-exit-container">
              <button class="consent-btn pm-focusable consent-btn-secondary" id="save-exit-btn" aria-label="${currentLang.a11y.saveAndCloseButton}">${currentLang.privacyPopin.saveAndCloseButton}</button>
            </div>
            <div class="footer-btn-container" id="accept-refuse-container">
              <button class="consent-btn pm-focusable" id="accept-btn" data-e2e="accept-button" aria-label="${currentLang.a11y.acceptButton}">${currentLang.privacyPopin.acceptButton}</button>
              <button class="consent-btn pm-focusable consent-btn-secondary" id="refuse-btn" aria-label="${currentLang.a11y.refuseButton}">${currentLang.privacyPopin.refuseButton}</button>
            </div>
            <button id="decline-btn" class="pm-focusable" aria-label="${currentLang.a11y.declineButton}">
              <span class="pm-toggle-button-text">${currentLang.privacyPopin.declineButton}</span>
              <svg
                focusable="false"
                class="arrow"
                id="right-arrow"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 17.85 32.69"
                aria-labelledby="title desc"
                role="img"
              >
                <path
                  d="M17.41 15.28L2.56.44A1.5 1.5 0 0 0 .44 2.56l13.78 13.79L.44 30.13a1.5 1.5 0 0 0 2.12 2.12l14.82-14.82a1.51 1.51 0 0 0 .03-2.15z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>`;
};
