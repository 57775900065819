/**
 * PrivacyManager's total optout function
 */
import {
  setAdsOptOut,
  setAnonymous,
  setStatsOptOut,
  syncUserPrefWithIframe,
  updateSessionId,
} from '../Actions/PrivacyActions';
import {
  initAfterUserConsent,
  sendEvent,
  sendPageView,
  storeUserChoiceOnTopDomainCookie,
} from '../Actions/TrackingActions';
import {
  COOKIE_NAME_ADS,
  COOKIE_NAME_ANONYMOUS,
  COOKIE_NAME_DEVICE_ID,
  COOKIE_NAME_SESSION_ID,
  COOKIE_NAME_STATS,
  DEFAULT_COOKIE_DURATION,
  SESSION_ID_DURATION_DAYS,
} from '../PrivacyManager/Constants';
import { cleanCookies, getCookie, setCookie } from '../Utils';

export const initTrackingtoolHelper = () => {
  Object.assign(window, {
    getAnonymousMeasure,
    getTealiumKey,
    setAdsConsent,
    setAnonymousMeasure,
    setStatsConsent,
    setTealiumKey,
    ttSetUserConsent,
    ttSetSessionId,
  });
};

/**
 * Sets the user's anonymous measurement value
 * - If **anonymousAllowed=true** then we can send anonymous data for analytics
 * - If **anonymousAllowed=false** then we do not send any data
 * @param {boolean} anonymousAllowed The anonymous measurement value
 */
const setAnonymousMeasure = (anonymousAllowed) => {
  const store = window.ttStore;

  if (anonymousAllowed) {
    Object.assign(window, {
      sendPageView,
      sendEvent,
    });
  } else {
    window.sendPageView = () => {};
    window.sendEvent = () => {};
    cleanCookies([COOKIE_NAME_DEVICE_ID, COOKIE_NAME_SESSION_ID]);
  }

  setCookie(COOKIE_NAME_ANONYMOUS, anonymousAllowed.toString(), DEFAULT_COOKIE_DURATION);
  setAnonymous(store, anonymousAllowed);
  if (!anonymousAllowed) {
    setStatsOptOut(store, false);
  }

  syncUserPrefWithIframe(store);
  // Write cookie session on the main domain (in addition to the iframe's cookies)
  storeUserChoiceOnTopDomainCookie(store);

  const state = store.getState();
  document.dispatchEvent(new CustomEvent('userChangedConsent', { detail: state }));
};

/**
 * Returns the user's anonymous value stored in the **anonymous** cookie,\
 * or **true** if there isn't any cookie stored
 * - If **anonymousAllowed=true** then we can send anonymous data for analytics
 * - If **anonymousAllowed=false** then we do not send any data
 */
const getAnonymousMeasure = () => getCookie(COOKIE_NAME_ANONYMOUS) !== 'false';

const getTealiumKey = () => {
  const store = window.ttStore;
  const state = store.getState();

  return state.config.tealiumKey;
};
const setTealiumKey = (tealiumKey) => {
  const store = window.ttStore;
  return store.dispatch({ type: 'UPDATE_TEALIUM_KEY', tealiumKey });
};

/**
 * Sets the user's stats value
 * - If **statsAllowed=true** then analytics measurement is allowed
 * - If **statsAllowed=false**  then analytics measurement is not allowed
 * @param {boolean} statsAllowed The analytics value
 */
const setStatsConsent = (statsAllowed) => {
  const store = window.ttStore;
  setCookie(COOKIE_NAME_STATS, statsAllowed.toString(), DEFAULT_COOKIE_DURATION);
  setStatsOptOut(store, statsAllowed);

  syncUserPrefWithIframe(store);
  // Write cookie session on the main domain (in addition to the iframe's cookies)
  storeUserChoiceOnTopDomainCookie(store);

  const state = store.getState();
  document.dispatchEvent(new CustomEvent('userChangedConsent', { detail: state }));
};

/**
 * Sets the user's ads value
 * - If **adsAllowed=true** then ads are allowed
 * - If **adsAllowed=false** then ads are not allowed
 * @param {boolean} adsAllowed The ads value
 */
const setAdsConsent = (adsAllowed) => {
  const store = window.ttStore;
  setCookie(COOKIE_NAME_ADS, adsAllowed.toString(), DEFAULT_COOKIE_DURATION);
  setAdsOptOut(store, adsAllowed);

  syncUserPrefWithIframe(store);
  // Write cookie session on the main domain (in addition to the iframe's cookies)
  storeUserChoiceOnTopDomainCookie(store);

  const state = store.getState();
  document.dispatchEvent(new CustomEvent('userChangedConsent', { detail: state }));
};

/**
 * Sets the user's session ID
 * - If **sessionId** is defined then we set the session ID cookie and update the store
 * @param {string} sessionId
 */
const ttSetSessionId = (sessionId) => {
  const store = window.ttStore;

  if (sessionId) {
    // clean session ID cookies and set the new session ID
    cleanCookies([COOKIE_NAME_SESSION_ID]);
    setCookie(COOKIE_NAME_SESSION_ID, sessionId, SESSION_ID_DURATION_DAYS, window.location.host.replace('www.', '.'));

    // Update the store with the new session ID
    updateSessionId(store, sessionId);
  }
};

/**
 * Sets the user's consent values
 * @param {boolean} anonymousAllowed The anonymous measurement value
 * @param {boolean} adsAllowed The ads value
 * @param {boolean} statsAllowed The stats value
 */
const ttSetUserConsent = ({ anonymousAllowed, statsAllowed, adsAllowed }) => {
  const store = window.ttStore;

  // Set the user's consent values analytics, trackingPub, and anonymousTracking
  setAnonymousMeasure(anonymousAllowed);
  setAdsConsent(adsAllowed);
  setStatsConsent(statsAllowed);

  // Send Popin display and interaction events
  sendPopinDisplayEvent(statsAllowed, statsAllowed);
  sendPopinInteractionEvent(statsAllowed, adsAllowed);

  //  CANAL+ respects the GDPR regulation
  // We add the tracking ONLY if the user expressed their consent
  initAfterUserConsent(store, true);
};
